<template>
  <v-card>
    <v-card-title class="text-h6">{{ item.barcode }}</v-card-title>
    <v-card-text>{{ $t("labels.update_goods_price_description") }}</v-card-text>

    <v-card-text>
      <v-row>
        <v-col cols="12">
          <input-number
            v-model.number="itemProperty.price"
            class="c-input-xs mb-3"
            type="number"
            :label="$t('labels.price')"
            dense
            outlined
            clearable
            hide-details
          />
        </v-col>
        <v-col cols="12">
          <input-number
            v-model.number="itemProperty.wholesale_price"
            class="c-input-xs mb-3"
            type="number"
            :label="$t('labels.wholesale_price')"
            dense
            outlined
            clearable
            hide-details
          />
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="red darken-1" text @click="cancel">
        {{ $t("labels.cancel") }}
      </v-btn>
      <v-btn color="green darken-1" text @click="confirm">
        {{ $t("labels.confirm") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { httpClient } from "@/libs/http";

export default {
  name: "UpdateComboPrice",
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    isLoading: false,
    itemProperty: {},
  }),
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    async confirm() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/goods-combo-update-price", {
          id: this.item.id,
          ...this.itemProperty,
        });
        this.isLoading = false;
        this.$emit("refreshData");
        this.cancel();
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
  },
  mounted() {
    this.itemProperty = { ...this.item };
  },
};
</script>

<style scoped></style>
